// extracted by mini-css-extract-plugin
export var arrow = "Slider-module--arrow--764b9";
export var next = "Slider-module--next--3f017";
export var nojs = "Slider-module--nojs--23d99";
export var onlyOne = "Slider-module--only-one--17d39";
export var onlyThree = "Slider-module--only-three--02127";
export var onlyTwo = "Slider-module--only-two--30052";
export var outer = "Slider-module--outer--09508";
export var pageFooter = "Slider-module--page-footer--27ec4";
export var prev = "Slider-module--prev--6c03c";
export var slickActive = "Slider-module--slick-active--69c5e";
export var slickSlide = "Slider-module--slick-slide--8af3e";
export var slickTrack = "Slider-module--slick-track--c5718";
export var slide = "Slider-module--slide--b4363";
export var textCenter = "Slider-module--textCenter--11cec";