// extracted by mini-css-extract-plugin
export var arrowRight = "Button-module--arrowRight--04d63";
export var arrowWhite = "Button-module--arrow-white--d56c2";
export var block = "Button-module--block--dc8c1";
export var blue = "Button-module--blue--4f633";
export var btn = "Button-module--btn--31859";
export var green = "Button-module--green--20695";
export var grey = "Button-module--grey--288d3";
export var greyInvertedArrow = "Button-module--grey-inverted-arrow--2fa00";
export var pageFooter = "Button-module--page-footer--d0e8a";
export var red = "Button-module--red--ef3eb";
export var textCenter = "Button-module--textCenter--0e20e";
export var white = "Button-module--white--9811e";