// extracted by mini-css-extract-plugin
export var arrow = "Pagination-module--arrow--6b6c5";
export var arrowContainer = "Pagination-module--arrow-container--afd80";
export var disabled = "Pagination-module--disabled--7e859";
export var dots = "Pagination-module--dots--4f962";
export var left = "Pagination-module--left--d907d";
export var noMarginTop = "Pagination-module--no-margin-top--9196d";
export var pageFooter = "Pagination-module--page-footer--9f517";
export var paginationContainer = "Pagination-module--pagination-container--14e0b";
export var paginationItem = "Pagination-module--pagination-item--4614a";
export var right = "Pagination-module--right--c60b4";
export var selected = "Pagination-module--selected--cf85b";
export var textCenter = "Pagination-module--textCenter--297e0";